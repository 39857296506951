import React, { useContext, useEffect } from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import styles from '../scss/components/_SplitSection.module.scss';

const SplitSection = (props) => {
    useEffect(()=>{
        if(props.budsummer){
            console.log("BudSummer!")
            document.getElementById("leftSection").style.backgroundColor = "#F90002"
            // document.getElementById("rightSection").style.backgroundImage = "#F90002"
        }
    },[])
    return (
        <Container fluid className={styles.SectionContainer} id="InfoSection">
            <img src={props.image} className={styles.imgBackground}/>
            <Row fluid className="m-0">
                <Col md={6} xs={12} className={styles.SectionColLeft} id="leftSection">
                    {props.left}
                </Col>
                <Col className={styles.SectionColRight} id="rightSection">
                    {props.right}
                </Col>
            </Row>
        </Container>
    )
}

export default SplitSection